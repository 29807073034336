import React, { useState, useEffect } from "react";
import BarChart from "@material-ui/icons/BarChart";
import { useTranslate } from "ra-core";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MetricForm from "./Form";
import { DataGrid } from "@material-ui/data-grid";
import { Link } from "react-admin";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";

const styles = {
  container: { display: "flex", padding: "0 0 0 16px" },
  flex: { display: "flex", marginBottom: "1em" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftSpacer: { flex: 1 },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  toolbar: {
    display: "flex",
    alignItems: "flex-start",
    gap: "1em",
    minWidth: "100%",
  },
  centered: { display: "flex", justifyContent: "center" },
};

const useStyles = makeStyles({
  formEmpty: {
    "& div": {
      display: "flex",
      marginRight: "8px",
    },
  },
  formTop: {
    "& div": {
      display: "flex",
      marginRight: "4px",
      maxWidth: "225px",
    },
  },
  link: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  label: {
    paddingLeft: "14px",
    "&.Mui-focused": {
      paddingTop: "14px",
    },
  },
  box: {
    padding: '16px',
    boxSizing: 'border-box',
    border: '1px solid #BFBFBF',
    borderRadius: '5px',
    maxWidth: '200px',
    position: 'absolute',
    top: '-80px',
    right: '0',
    background: '#fff'
  },
  totaltitle: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '20px'
  },
  total: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '22px',
    marginTop: '8px'
  }
});

// ========================================================================
// Componente principal
// ========================================================================
const ViewVisitedUrl = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [data, setData] = useState(null);
  const [formData, setFormData] = useState({});
  const [serverData, setServerData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [choices, setChoices] = useState([]);
  const [plans, setPlans] = useState([]);
  const [category, setCategory] = useState([]);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (data) {
      let calctotal = data?.reduce((acc, i) => { return (acc + i?.number_visited || 0) }, 0);
      setTotal(calctotal);
    }
  }, [data])

  const [filter, setFilter] = useState({
    user: null,
    category: [],
    plan: [],
  });
  const getDate = ({ row }) => {
    let date =
      new Date(
        row.users.find((i) => i.id === selectedUser)?.pivot?.created_at
      )?.toLocaleDateString() || "";
    return date;
  };

  const getCategory = ({ row }) => {
    let category = row?.note?.category?.name || "";
    return category;
  };

  const CustomLink = ({ params }) => {
    return params?.value?.id ? (
      <Link
        to={`notes/${params.value.id}`}
        target="_blank"
        onClick={(e) => e.stopPropagation()}
      >
        {params.value.id}
      </Link>
    ) : null;
  };
  const formatDate = (dateString) => {
    if (!dateString) {
      return '';
    }
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleString('es-ES', options);
  };

  // ========================================================================
  // Inicializa la tabla de sub-url
  // ========================================================================
  const dataColumns = [
    {
      field: "page_url",
      headerName: translate("resources.metrics.titles.url"),
      flex: 4,
    },
    {
      field: "number_visited",
      headerName: translate("resources.metrics.titles.count_users"),
      flex: 1,
      description: translate("resources.metrics.descriptions.count_users"),
    },
    {
      field: "category",
      headerName: "Categoría",
      flex: 1,
      valueGetter: (row) => getCategory(row),
    },
    {
      field: "note",
      headerName: "ID de Nota",
      flex: 1,
      renderCell: (params) => <CustomLink params={params} />,
    },
    {
      field: "date_publish",
      headerName: "Fecha de creación",
      flex: 1,
      valueFormatter: ({ value }) => formatDate(value),
    },
  ];

  const dataColumnsUser = [
    {
      field: "page_url",
      headerName: translate("resources.metrics.titles.url"),
      flex: 5,
    },
    {
      field: "date",
      headerName: translate("components.date"),
      flex: 2,
      valueGetter: (row) => getDate(row),
    },
    {
      field: "category",
      headerName: translate("components.category"),
      flex: 1,
      valueGetter: (row) => getCategory(row),
    },
    {
      field: "note",
      headerName: translate("components.note"),
      flex: 1,
      renderCell: (params) => <CustomLink params={params} />,
    },
  ];

  const handleOnCellClick = (params) => {
    const url = process.env.REACT_APP_URL_CLIENT + `${params?.row?.page_url}`;
    if (url) {
      window.open(url, "_blank");
    }
  };
  function compareName(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  function getCategories() {
    const cats = serverData.urls?.map((a) => a?.note?.category);
    let uniqueItems = [...new Set(cats.map((data) => data?.id))];
    uniqueItems = uniqueItems.filter((i) => i != null);
    let categories = uniqueItems.map((i) =>
      cats.find((v) => v?.id === i && i !== undefined)
    );
    categories = categories.sort(compareName);
    categories = [{ id: null, name: translate("components.see_all") }, ...categories];
    setCategory(categories);
  }

  function getPlans() {
    const plans = serverData.users.map((a) => a?.subscription_active?.plan);
    const uniqueItems = plans.reduce((acc, currentValue) => {
      if (currentValue) {
        if (typeof acc.ids[currentValue.id] == 'undefined') {
          acc.ids[currentValue.id] = 1;
          acc.plans.push({ id: currentValue.id, name: currentValue.name });
        } else {
          acc.ids[currentValue.id] = acc.ids[currentValue.id] + 1;
        }
      }
      return acc;
    }, { ids: {}, plans: [] });
    let result = uniqueItems.plans.sort(compareName);
    result = [
      { id: null, name: translate("components.see_all") },
      { id: "none", name: translate("components.no_plan") },
      ...result,
    ];
    setPlans(result);
  }

  function compareUserName(a, b) {
    if (a.username < b.username) {
      return -1;
    }
    if (a.username > b.username) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    if (serverData?.users) {
      getPlans();
      let users = serverData.users;
      users = users.sort(compareUserName);
      setChoices([{ id: null, username: translate("components.see_all") }, ...users]);
    }
    if (serverData?.urls) {
      getCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverData]);

  const filterByUser = (user) => {
    //filter users where id is equal to url visited by users.
    setFilter({ ...filter, user: user });
  };

  const applyfilter = () => {
    if (serverData.urls) {
      let result = JSON.parse(JSON.stringify(serverData.urls));

      if (filter.category.length > 0) {
        result = result.filter((v) =>
          filter.category.includes(v.note?.category?.id)
        );
      }

      if (filter.user) {
        result = result.filter((v) =>
          v.users.some((u) => u.id === Number(filter.user))
        );
        result.forEach((item) => {
          item.number_visited =
            item.users.find((i) => i.id === filter.user)?.number_visited || 0;
        });
      }
      if (filter.plan.length > 0) {
        let userswithplan = serverData.users.filter((v) =>
          filter.plan.includes(v?.subscription_active?.plan?.id)
        );
        let witoutplan = serverData.users.filter(
          (v) => v.subscription_active == null
        );
        let u = witoutplan.find(v => v.id === filter?.user);
        if ((filter.plan.includes("none") && !filter?.user) || (u && !u?.subscription_active)) {
          userswithplan = userswithplan.concat(witoutplan);
        }
        result = result.filter((v) =>
          v.users.some((u) => userswithplan.some((uw) => uw.id === u.id))
        );
        if (result?.length > 0) {
          result?.forEach((item) => {
            if (!filter?.user) {
              item.number_visited = item.users.reduce((acc, i) => {
                if (userswithplan.find((v) => v.id === i.id)) {
                  return acc + i.number_visited;
                } else return acc;
              }, 0);
            } else {
              item.users_count =
                item?.users?.filter(
                  (u) => userswithplan.filter((v) => v.id === u.id)?.length
                )?.length || 0;
            }
          });
        }
      }
      setData(result);
    }
  };

  const filterByCategory = (cat) => {
    //filter categories where id is equal to category of url visited.
    if (cat.includes(null)) {
      setFilter({ ...filter, category: [] });
    } else {
      setFilter({ ...filter, category: cat });
    }
  };

  const filterByPlan = (plan) => {
    //filter categories where id is equal to plan of url visited.
    if (plan.includes(null)) {
      setFilter({ ...filter, plan: [] });
    } else {
      setFilter({ ...filter, plan: plan });
    }
  };

  useEffect(() => {
    applyfilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  // ========================================================================
  // Component body
  // ========================================================================
  return (
    <>
      {data ? (
        <>
          <div style={styles.header}>
            <MetricForm
              data={data}
              jss={classes.form + " " + classes.formTop}
              setData={setData}
              setserverdata={setServerData}
              formdata={formData}
              setformdata={setFormData}
              setSelectedUser={setSelectedUser}
            />
          </div>
          <Box paddingLeft={"16px"}>
            {choices.length > 1 && (
              <FormControl>
                <InputLabel id="select-label" className={classes.label}>
                  {translate("resources.metrics.titles.filter_registered")}
                </InputLabel>
                <Select
                  variant="filled"
                  labelId="select-label"
                  id="select-user"
                  value={filter?.user}
                  style={{ minWidth: "220px", marginRight: "1em" }}
                  label={translate("resources.metrics.input.users")}
                  onChange={(e) => filterByUser(e.target.value)}
                >
                  {choices?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.username}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {category.length > 1 && (
              <FormControl>
                <InputLabel
                  id="select-category-label"
                  className={classes.label}
                >
                  {translate("components.category")}
                </InputLabel>
                <Select
                  variant="filled"
                  labelId="select-category-label"
                  id="select-category"
                  style={{ minWidth: "220px", marginRight: "1em" }}
                  label={"Categoría"}
                  value={filter?.category}
                  multiple
                  onChange={(e) => filterByCategory(e.target.value)}
                >
                  {category?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {plans.length > 1 && (
              <FormControl>
                <InputLabel id="select-plan-label" className={classes.label}>
                  {translate("resources.plans.name")}
                </InputLabel>
                <Select
                  variant="filled"
                  labelId="select-plan-label"
                  id="select-plan"
                  multiple
                  value={filter?.plan}
                  style={{ minWidth: "220px", marginRight: "1em" }}
                  label={"Plan"}
                  onChange={(e) => filterByPlan(e.target.value)}
                >
                  {plans?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
          <Box position="relative" width='100%'>
            <Box className={classes.box}>
              <Typography className={classes.totaltitle} >{translate("components.total_views")}</Typography>
              <Typography className={classes.total}>{total}</Typography>
            </Box>
          </Box>
          <div style={{ height: 640, width: "100%", marginBottom: "50px" }}>
            <DataGrid
              style={{ marginLeft: "1em", marginTop: "1em" }}
              rows={data}
              columns={selectedUser ? dataColumnsUser : dataColumns}
              pageSize={10}
              disableColumnMenu
              disableSelectionOnClick
              onCellClick={handleOnCellClick}
            />
          </div>
        </>
      ) : (
        <Box textAlign="center" m={1} maxWidth={1200}>
          <BarChart style={{ fontSize: "150px" }} color="disabled" />
          <Typography variant="h6">
            {translate("resources.metrics.validation.empty_message")}
          </Typography>
          <Box style={styles.centered} m={1}>
            <MetricForm
              data={data}
              setserverdata={setServerData}
              jss={classes.form + " " + classes.formEmpty}
              setData={setData}
              formdata={formData}
              setformdata={setFormData}
              setSelectedUser={setSelectedUser}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default ViewVisitedUrl;
